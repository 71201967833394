.container {
  display: "grid";
  place-items: "center";

  border: "1px solid #CCCCCC";

  /* display: flex; */
  /* flex-wrap: wrap; */
}

.icon {
  width: 50%;
}

@media (max-width: 768px) {
  /* Estilos para pantallas pequeñas */
  /* .container {
    display: "grid";
    place-items: "center";
    grid-template-columns: repeat(1, 1fr);
  } */
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Estilos para pantallas medianas */
  /* .container {
    display: "grid";
    place-items: "center";
    grid-template-columns: repeat(2, 1fr);   
  } */
}

@media (min-width: 1025px) {
  /* Estilos para pantallas grandes */
  /* .container {
    display: "grid";
    place-items: "center";
    grid-template-columns: repeat(2, 1fr);
  } */
}
